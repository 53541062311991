import * as Ariakit from '@ariakit/react'
import { useQueryErrorResetBoundary } from '@tanstack/react-query'
import classnames from 'classnames'
import * as React from 'react'
import { ErrorBoundary } from 'react-error-boundary'

import { getSanitizedTicker } from '../../../app/shared/ticker-sanitizer'
import { ComboBox, useComboBoxState } from '../combo-box'
import { IconNameType } from '../icon'
import { InputSize, InputTheme } from '../input'
import { SearchError } from './search-error'
import { SearchList } from './search-list'
import { getRedirectForTicker } from './util'

interface SearchProps {
  autoFocus?: boolean
  defaultValue?: string
  defaultPlacement?: Ariakit.ComboboxStoreProps['placement']
  placeholder?: string
  theme?: keyof typeof InputTheme
  size?: keyof typeof InputSize
  inputClass?: string
  popoverClass?: string
  chartIndex?: number
  isMatchInputAndBoxWidth?: boolean
  isSearchIcon?: boolean
  isShowAllVisible?: boolean
  isSearchItemLink?: boolean
  rightContent?: JSX.Element | IconNameType
  onTickerSelected?: (ticker: string) => void
}

export function Search({
  isSearchIcon = true,
  isShowAllVisible = true,
  isSearchItemLink = true,
  ...props
}: SearchProps) {
  const { reset } = useQueryErrorResetBoundary()
  const state = useComboBoxState({
    defaultValue: props.defaultValue,
    placement: props.defaultPlacement ?? 'bottom-start',
    setSelectedValue: (value) => {
      if (isSearchItemLink) {
        window.location.href = getRedirectForTicker(value as string)
      } else {
        props.onTickerSelected?.(getSanitizedTicker(value as string))
        // We have to reset the value (clear the input) in next tick otherwise it doesn't work
        setTimeout(() => state.setValue(''))
      }
    },
  })
  const value = state.useState('value')
  const activeValue = state.useState('activeValue')
  const isMounted = state.useState('mounted')

  return (
    <ComboBox
      state={state}
      minValueLength={1}
      aria-label="Search"
      autoFocus={props.autoFocus}
      inputProps={{
        theme: props.theme,
        hasFocusStyle: !activeValue,
        size: props.size,
        placeholder: props.placeholder ?? 'Search symbol',
        autoComplete: 'off',
        autoCorrect: 'off',
        autoCapitalize: 'off',
        maxLength: 100,
        onBlur: (e) => {
          // prevent combobox from setting input value on input blur
          e.preventDefault()
        },
        inputClass: classnames(props.inputClass, {
          'focus:border-blue-500 dark:focus:border-blue-400': !!value && !activeValue,
        }),
        rightContent: props.rightContent,
        ...(isSearchIcon && { leftContent: 'search' }),
      }}
      popoverProps={{
        sameWidth: props.isMatchInputAndBoxWidth,
        className: classnames(props.popoverClass, 'h-64 w-full sm:h-auto', {
          'sm:w-110': !props.isMatchInputAndBoxWidth,
        }),
        fitViewport: !props.isMatchInputAndBoxWidth,
      }}
      {...(props.chartIndex !== undefined && { inputTestId: `chart-${props.chartIndex}-settings-search-input` })}
    >
      <div className="flex flex-1 flex-col space-y-2 overflow-hidden sm:flex-row sm:space-x-8 sm:space-y-0">
        {isMounted && (
          <ErrorBoundary FallbackComponent={SearchError} onReset={reset} resetKeys={[value, activeValue]}>
            <SearchList
              state={state}
              chartIndex={props.chartIndex}
              isShowAllVisible={isShowAllVisible}
              isSearchItemLink={isSearchItemLink}
            />
          </ErrorBoundary>
        )}
      </div>
    </ComboBox>
  )
}
