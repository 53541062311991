// Export the Icon component itself
export * from './icon'

export function isValidIconName(name?: unknown): name is IconNameType {
  if (!name || typeof name !== 'string') return false

  return iconList[name as IconNameType] !== undefined
}

export type IconNameType = keyof typeof iconList

const root = ''
const chartLayouts = 'chart-layouts'
const chartTools = 'chart-tools'
const chartTypes = 'chart-types'

// Export list of icon components
export const iconList = {
  // Main icons
  add: root,
  arrowDownShort: root,
  arrowForward: root,
  arrowUpShort: root,
  briefcase: root,
  calendar: root,
  caretDown: root,
  caretLeft: root,
  caretRight: root,
  caretUp: root,
  caretVertical: root,
  chartBar: root,
  chartBubble: root,
  chartPie: root,
  chartTable: root,
  chartTreemap: root,
  chevronDown: root,
  chevronRight: root,
  close: root,
  darkMode: root,
  diamondOutlined: root,
  done: root,
  drag: root,
  draw: root,
  empty: root,
  exitFullscreen: root,
  flash: root,
  fullscreen: root,
  gesture: root,
  gift: root,
  hamburger: root,
  help: root,
  idea: root,
  ideaLoad: root,
  ideaSave: root,
  info: root,
  interval: root,
  lightMode: root,
  lock: root,
  lockOpened: root,
  menuClose: root,
  minus: root,
  more: root,
  note: root,
  palette: root,
  profile: root,
  publish: root,
  refresh: root,
  remove: root,
  restore: root,
  search: root,
  settings: root,
  settingsWheel: root,
  share: root,
  signOut: root,
  star: root,
  starOutlined: root,
  switchHorizontal: root,
  switchVertical: root,
  tabKey: root,
  template: root,
  trashCan: root,
  trendingDown: root,
  trendingUp: root,
  triangle: root,
  triangleOutlineDown: root,
  triangleOutlineUp: root,
  warning: root,

  // Chart layouts
  layout1h1v: chartLayouts,
  layout1h2v: chartLayouts,
  layout1h3v: chartLayouts,
  layout1l2r: chartLayouts,
  layout1t2b: chartLayouts,
  layout2h1v: chartLayouts,
  layout2h2v: chartLayouts,
  layout3h1v: chartLayouts,
  layout3h2v: chartLayouts,

  // Chart tools
  drawingActionDelete: chartTools,
  drawingSettingAutosaveOff: chartTools,
  drawingSettingAutosaveOn: chartTools,
  drawingSettingAutosaveSaving: chartTools,
  drawingSettingDrawingMode: chartTools,
  drawingSettingDrawingModeActive: chartTools,
  drawingSettingHide: chartTools,
  drawingSettingLock: chartTools,
  drawingSettingPreserveDrawings: chartTools,
  drawingSettingPreserveDrawingsActive: chartTools,
  drawingSettingShow: chartTools,
  drawingSettingUnlock: chartTools,
  toolArrow: chartTools,
  toolBrush: chartTools,
  toolCallout: chartTools,
  toolCurve: chartTools,
  toolElliottWave: chartTools,
  toolEllipse: chartTools,
  toolExpand: chartTools,
  toolFibonacci: chartTools,
  toolLine: chartTools,
  toolMeasure: chartTools,
  toolMidLineH: chartTools,
  toolMidLineV: chartTools,
  toolMouse: chartTools,
  toolPitchfork: chartTools,
  toolPolygon: chartTools,
  toolPosition: chartTools,
  toolRect: chartTools,
  toolRectRotated: chartTools,
  toolText: chartTools,
  toolTriangle: chartTools,
  toolXABCD: chartTools,

  // Chart types
  candleBarBasic: chartTypes,
  candleBarHeikinAshi: chartTypes,
  candleBarHollow: chartTypes,
  lineChartType: chartTypes,
  ohlcChartType: chartTypes,
}
