export const CRYPTO_TICKERS = [
  'BTCUSD',
  'LTCUSD',
  'ETHUSD',
  'XRPUSD',
  'BCHUSD',
  'BTCEUR',
  'LTCEUR',
  'ETHEUR',
  'XRPEUR',
  'BCHEUR',
  'LTCBTC',
  'ETHBTC',
  'XRPBTC',
  'BCHBTC',
]

// Keep in sync with the copy in Charts repo - app/constants/common.ts
export enum TIMEFRAME {
  i1 = 'i1',
  i2 = 'i2',
  i3 = 'i3',
  i5 = 'i5',
  i10 = 'i10',
  i15 = 'i15',
  i30 = 'i30',
  h = 'h',
  h2 = 'h2',
  h4 = 'h4',
  d = 'd',
  w = 'w',
  m = 'm',
}

export enum SpecificChartFunctionality {
  default = 'default',
  quotePage = 'quotePage',
  quotePerf = 'quotePerf',
  quoteFinancials = 'quoteFinancials',
  chartPage = 'chartPage',
  smallIndex = 'smallIndex',
  offScreen = 'offScreen',
  forexPage = 'forexPage',
  futuresPage = 'futuresPage',
  cryptoPage = 'cryptoPage',
}

export enum QuoteRoutes {
  OptionsChain = 'oc',
  OptionsVolatility = 'ocv',
  Revenue = 'rv',
  Dividends = 'dv',
  Earnings = 'ea',
  Performance = 'p',
  ShortInterest = 'si',
}

export const FINANCIALS_SECTION_LABELS = {
  [QuoteRoutes.Revenue]: 'Revenue',
  [QuoteRoutes.Dividends]: 'Dividends',
  [QuoteRoutes.Earnings]: 'Earnings',
}

export const OPTIONS_SECTION_LABELS = {
  [QuoteRoutes.OptionsChain]: 'Prices',
  [QuoteRoutes.OptionsVolatility]: 'Volatility & Greeks (BETA)',
}
