import classnames from 'classnames'
import * as React from 'react'

import { IconNameType, iconList } from '.'
import { SVGProps } from './svg'

export interface IconProps extends SVGProps {
  /**
   * Icon name
   */
  name?: IconNameType

  /**
   * Alternative way to pass icon to render if you want to render a custom component
   * or use `iconList` object lookup
   */
  as?: React.FC<SVGProps>
}

export function Icon({ name, as: asComponent, className, width = 16, height = width, ...props }: IconProps) {
  if (name && asComponent) {
    throw new Error('Specifying both "name" and "as" on Icon component is not supported')
  }

  if (!name && !asComponent) {
    throw new Error('No icon specified')
  }

  if (name) {
    if (iconList[name] === undefined) {
      throw new Error('Icon not found')
    }

    const fileName = ['icons', iconList[name]].filter(Boolean).join('_')

    return (
      <svg
        {...props}
        className={classnames('pointer-events-none block shrink-0 fill-current', className)}
        width={width}
        height={height}
      >
        <use href={`/assets/dist-icons/${fileName}.svg?rev=${FinvizSettings.versionImages}#${name}`} />
      </svg>
    )
  }

  const Component = asComponent!
  return <Component {...props} className={className} width={width} height={height} />
}
