import * as Ariakit from '@ariakit/react'
import classnames from 'classnames'
import * as React from 'react'

import { Box } from '../box'
import { Input, InputProps } from '../input'

interface ComboBoxProps extends Partial<Omit<Ariakit.ComboboxProps, 'store' | 'onChange'>> {
  /**
   * The returned object from `useComboBoxState`
   */
  state: Ariakit.ComboboxStore

  /**
   * Additional props for the input
   */
  inputProps?: Partial<InputProps> & Omit<Partial<Ariakit.ComboboxProps>, 'size'>

  /**
   * Additional props for the popover
   */
  popoverProps?: Partial<Ariakit.ComboboxPopoverProps>

  /**
   * Id for testing purposes
   */
  inputTestId?: string

  /**
   * Minimum num of chars in input to show the popover
   */
  minValueLength?: number
}

export function ComboBox({
  state,
  children,
  inputProps,
  popoverProps,
  inputTestId,
  'aria-label': ariaLabel,
  minValueLength = 0,
  ...props
}: React.PropsWithChildren<ComboBoxProps>) {
  const isMounted = state.useState('mounted')
  const value = state.useState('value')
  const activeValue = state.useState('activeValue')
  const placement = state.useState('currentPlacement')

  return (
    <>
      <Ariakit.Combobox
        store={state}
        autoFocus={props.autoFocus ?? false}
        value={activeValue || value}
        render={
          <Input
            {...inputProps}
            aria-label={ariaLabel}
            data-testid={inputTestId}
            onKeyDown={(ev: React.KeyboardEvent<HTMLInputElement>) => {
              if (ev.key === 'Enter') {
                state.setSelectedValue(value)
              }
              inputProps?.onKeyDown?.(ev)
            }}
          />
        }
        {...props}
      />
      {isMounted && value.length >= minValueLength && (
        <Ariakit.ComboboxPopover
          portal
          gutter={4}
          unmountOnHide
          render={<Box />}
          store={state}
          aria-label={ariaLabel}
          {...popoverProps}
          className={classnames(
            'z-dialog flex flex-col items-stretch space-y-px p-2 opacity-0 outline-none transition duration-[250ms] will-change-transform data-[enter]:translate-x-0 data-[enter]:translate-y-0 data-[enter]:opacity-100 motion-safe:transform',
            {
              'motion-safe:translate-y-1': placement.startsWith('top'),
              'motion-safe:-translate-x-1': placement.startsWith('right'),
              'motion-safe:-translate-y-1': placement.startsWith('bottom'),
              'motion-safe:translate-x-1': placement.startsWith('left'),
            },
            popoverProps?.className
          )}
        >
          {children}
        </Ariakit.ComboboxPopover>
      )}
    </>
  )
}
