import { FallbackProps } from 'react-error-boundary'

import { link } from '../../services/routing'
import { Button } from '../button'
import { Centered } from '../centered'
import { Icon } from '../icon'
import { Heading, Paragraph } from '../typography'

export function SearchError(props: FallbackProps) {
  return (
    <Centered className="h-52 flex-1 flex-col">
      <Icon width={24} name="warning" className="text-gray-600" />
      <Heading level={5} className="mt-5">
        Error fetching the results
      </Heading>
      <Paragraph size="small" className="w-64 text-center text-gray-600 dark:text-gray-400">
        There’s been an error fetching your results. Please try again or{' '}
        <a href={link.toContact()} className="text-blue-600">
          contact support
        </a>
        .
      </Paragraph>
      <Button className="mt-6" theme="blueHover" onClick={() => props.resetErrorBoundary()}>
        Retry
      </Button>
    </Centered>
  )
}
