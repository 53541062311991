import * as Ariakit from '@ariakit/react'
import { useQuery } from '@tanstack/react-query'
import React from 'react'

import { searchApiRequest } from '../../services/api'
import { link } from '../../services/routing'
import { Button } from '../button'
import { Centered } from '../centered'
import { Spinner } from '../spinner'
import { SearchItem } from './search-item'
import { getRedirectForTicker } from './util'

interface SearchListProps {
  state: Ariakit.ComboboxStore
  chartIndex?: number
  isShowAllVisible: boolean
  isSearchItemLink: boolean
}

export function SearchList(props: SearchListProps) {
  const value = props.state.useState('value')
  const activeValue = props.state.useState('activeValue')
  const { data, isLoading } = useQuery(['search', value], () => searchApiRequest(value), {
    enabled: value.length > 0,
    keepPreviousData: true,
    cacheTime: Infinity,
    staleTime: Infinity,
  })
  const { hide } = props.state

  React.useEffect(() => {
    if (!isLoading && !data?.length && !props.isShowAllVisible) {
      hide()
    }
  }, [isLoading, data, hide, props.isShowAllVisible])

  if (isLoading) {
    return (
      <Centered className="h-52 flex-1">
        <Spinner />
      </Centered>
    )
  }

  return (
    <div className="flex flex-1 flex-col space-y-2 overflow-hidden">
      {data && data.length !== 0 && (
        <div className="flex grow flex-col overflow-auto">
          {data.map((item) => (
            <SearchItem
              state={props.state}
              key={item.ticker}
              item={item}
              chartIndex={props.chartIndex}
              {...(props.isSearchItemLink
                ? {
                    as: 'a',
                    href: getRedirectForTicker(item.ticker),
                  }
                : {})}
            />
          ))}
        </div>
      )}
      {props.isShowAllVisible && (
        <Button
          as="a"
          className="w-full shrink-0 self-center"
          contentClass="text-center"
          href={link.toSearch(encodeURIComponent(activeValue ?? value))}
        >
          Show All Results
        </Button>
      )}
    </div>
  )
}
