import {
  dedupeIntegration,
  extraErrorDataIntegration,
  httpClientIntegration,
  thirdPartyErrorFilterIntegration,
} from '@sentry/browser'

export const sentryOptions = {
  dsn: 'https://11de51e753454212a2845e5899a38cae@sentry.io/33153',
  allowUrls: [/finviz\.com/, /www\.finviz\.com/, /elite\.finviz\.com/],
  release: typeof window !== 'undefined' && 'FinvizSentrySettings' in window ? FinvizSentrySettings.release : undefined,
  sendDefaultPii: true,
  integrations: [
    dedupeIntegration(),
    extraErrorDataIntegration(),
    httpClientIntegration(),
    thirdPartyErrorFilterIntegration({
      filterKeys: ['finviz'], //NOTE: same application keys as specified in Sentry bundle plugin `webpack.config.js` -> `sentryWebpackPlugin`
      behaviour: 'apply-tag-if-exclusively-contains-third-party-frames',
    }),
  ],
  ignoreErrors: [
    'TypeError: Failed to fetch',
    'TypeError: NetworkError when attempting to fetch resource.',
    'ChunkLoadError: Loading chunk',
    'Error: Loading CSS chunk',
    /^TypeError: Load failed$/,
  ],
}
