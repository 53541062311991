import { Component, MouseEvent as ReactMouseEvent } from 'react'

class Settings extends Component {
  state = { open: false }

  componentWillUnmount() {
    document.removeEventListener('click', this._onDocumentClick)
  }

  render() {
    var classes = 'nav-link sign-in account-btn is-account'
    if (this.state.open) {
      classes += ' is-open'
    }
    return (
      <div style={{ position: 'relative' }}>
        <a href="#" className={classes} onClick={this._showDropDown}>
          <span className="fa fa-user"></span>
          {FinvizSettings.name}
        </a>
        <ul className="dropdown-menu" style={{ display: this.state.open ? 'block' : 'none' }}>
          <li>
            <a className="menu-item" href="/myaccount.ashx">
              Settings
            </a>
          </li>
          <li>
            <a className="menu-item" href="/logout.ashx">
              Sign out
            </a>
          </li>
        </ul>
      </div>
    )
  }

  _showDropDown = (e: ReactMouseEvent) => {
    e.preventDefault()
    e.stopPropagation()

    if (this.state.open) {
      this.setState({ open: false })
      return
    }

    this.setState({ open: true })
    document.addEventListener('click', this._onDocumentClick)
  }

  _hideDropDown = () => {
    this.setState({ open: false })
    document.removeEventListener('click', this._onDocumentClick)
  }

  _onDocumentClick = (e: MouseEvent) => {
    // @ts-ignore @REFACTOR
    if (e.target.className.indexOf?.('menu-item') === -1) {
      this._hideDropDown()
    }
  }
}

export default Settings
